import React from 'react';
import styled from 'styled-components';
import { Flex, Button, Box, Truncate, Text, Card, Image } from 'rebass';
import Img from 'gatsby-image';
import { Link, navigate } from 'gatsby';

import { colors, shadows } from '../theme';
import Icon from './Icon';
import { tagColor } from '../utils/helpers';

const IconButton = styled(Card)`
  /* cursor: pointer; */
  text-align: left;
  line-height: 1.5;
  text-align: center;
  height: 275px;
  box-shadow: ${shadows.small};
  padding: 0px;

  &:hover {
    background-color: ${colors.white};
    box-shadow: ${shadows.medium};
  }

  &:focus {
    box-shadow: ${shadows.outline};
  }
`;

const MainImage = styled(Img)`
  width: 100%;
  height: 200px;
`;

const HeartIcon = styled(Icon)`
  color: gray;
  height: 15px;
  width: 15px;
  padding-top: 2px;
`;

const RatingDiv = styled.div`
  position: relative;
  float: right;
  margin-top: -35px;
  margin-right: 5px;
  z-index: 1;
  top: 40px;
  border-radius: 5px;
  background-color: #f9f9fa;
`;

const Tag = styled.div`
  position: relative;
  float: right;
  margin-top: -35px;
  margin-right: 5px;
  z-index: 1;
  top: 70px;
  border-radius: 5px;
  background-color: #f9f9fa;
`;

const ExpTag = styled.div`
  position: relative;
  float: right;
  margin-top: -35px;
  margin-right: 5px;
  z-index: 1;
  top: 90px;
  border-radius: 5px;
  background-color: #f9f9fa;
`;

const EmojiTag = styled.div`
  position: relative;
  float: left;
  margin-top: -35px;
  margin-left: 12px;
  z-index: 1;
  top: 40px;
`;

const TagText = styled(Text)`
  font-size: 11px;
  margin-left: 5px;
  margin-right: 5px;
`;

const CardText = styled(Text)`
  padding-bottom: 10px;
  padding-top: 3px;
  text-align: left;
`;

class MainPost extends React.Component {
  renderEmoji() {
    if (this.props.data.url && this.props.data.youtubeid == null) {
      return (
        <EmojiTag>
          <Text fontSize={3}>📦</Text>
        </EmojiTag>
      );
    } else if (this.props.data.youtubeid) {
      return (
        <EmojiTag>
          <Text fontSize={3}>🎥</Text>
        </EmojiTag>
      );
    }
    return (
      <EmojiTag>
        <Text fontSize={3}>📄</Text>
      </EmojiTag>
    );
  }

  render() {
    const prevState = this.props.history;
    let tagsLow;
    let tagsUp;
    let mainTag;
    let expTag;
    if (this.props.data.tags != null) {
      tagsLow = this.props.data.tags[0];
      tagsUp = this.props.data.tags[0].toUpperCase();
      mainTag = (
        <a href={`/?${tagsLow}`}>
          <Tag>
            <TagText color={tagColor(tagsUp)}>{tagsUp}</TagText>
          </Tag>
        </a>
      );
    }

    if (this.props.data.experiment == true && this.props.data.tags == null) {
      expTag = (
        <a href="/?experiment">
          <Tag>
            <TagText color="#de98ff">EXPERIMENT</TagText>
          </Tag>
        </a>
      );
    } else if (
      this.props.data.experiment == true &&
      this.props.data.tags != null
    ) {
      expTag = (
        <a href="/?experiment">
          <ExpTag>
            <TagText color="#de98ff">EXPERIMENT</TagText>
          </ExpTag>
        </a>
      );
    }

    return (
      <IconButton color="gray9" bg="gray0">
        <Link to={this.props.slug} state={{ fromFeed: true }}>
          <RatingDiv>
            <Flex py="4px" pl="5px">
              <HeartIcon name="trending-up" color="gray9" />
              <Text fontSize="12px" color="gray8" px={2}>
                {this.props.data.hearts}
              </Text>
            </Flex>
          </RatingDiv>
        </Link>
        {this.renderEmoji()}
        {mainTag}
        {expTag}
        <Link to={this.props.slug} state={{ fromFeed: true }}>
          <MainImage fluid={this.props.data.featuredImage.fluid} />
        </Link>
        <Flex alignItems="center" flexWrap="wrap" px={2} py={2}>
          <Link to={this.props.slug} state={{ fromFeed: true }}>
            <CardText
              color="gray9"
              fontSize={['.9em', '.9em', '1.1em']}
              mb={2}
              mx={3}
            >
              {this.props.name}
            </CardText>
          </Link>
        </Flex>
      </IconButton>
    );
  }
}

export default MainPost;
