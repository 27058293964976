export function plural(count, noun, suffix = 's') {
  return `${count} ${noun}${count !== 1 ? suffix : ''}`;
}

const maybePluralize = (count, noun, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

export function tagColor(tag) {
  if (tag === 'SUCCESS') return '#88f992';
  else if (tag === 'FAILED') return '#e69b9c';
  else if (tag === 'EXPERIMENT') return '#de98ff';
  else if (tag === 'BUILDING') return '#0266ff';
}
