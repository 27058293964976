import React from 'react';
import { Flex, Box, Text, Card, Heading } from 'rebass';
import MainPost from './MainPost';

class MainGrid extends React.Component {
  render() {
    // const recentPosts = this.props.articles.edges.sort((a, b) => {
    //   let dateA = new Date(a.node.date),
    //     dateB = new Date(b.node.date);
    //   return dateB - dateA;
    // });

    // const popularPosts = this.props.articles.edges.sort(
    //   (a, b) => b.node.hearts - a.node.hearts
    // );

    return (
      <Flex flexWrap="wrap" my={20}>
        {this.props.posts.map(post => (
          <Box key={post.node.title} w={[1, 1 / 2]} px="15px" py="20px">
            <MainPost
              // Clean this up to only send data
              name={post.node.title}
              slug={post.node.slug}
              data={post.node}
              w={1}
            />
          </Box>
        ))}
      </Flex>
    );
  }
}

export default MainGrid;
