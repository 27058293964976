import React from "react";
import {
  BlockLink,
  Heading,
  Button,
  ButtonOutline,
  Flex,
  Image,
  Box,
  Input,
  Label
} from "rebass";
import styled from "styled-components";
import stdlib from "../images/stdlib-logo.svg";
import logOutboundLink from "../utils/log-outbound-link";
import logDownload from "../utils/log-download";
import Text from "./Text";
import { Select } from "./Select";
import Icon from "./Icon";
import LinkText from "./LinkText";
import Hide from "../../node_modules/hidden-styled";

const SelectText = Text.extend`
  text-align: right;
  margin-right: 7px;
  padding-top: 5px;
  width: 85px;
`;

const FunBox = styled(Box)`
  background-color: #fff;
  font-size: 1.1em;
  border-bottom: 1px;
  border-color: #d6d6e3;
`;

const IconBox = styled(Flex)``;

class MainSelector extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     selectedSortOption: this.props.filterOption,
  //     selectedTypeOption: this.props.typeOption,
  //   };
  // }

  handleSortChange = e => {
    // get the right option from the dropdown

    // this.setState({ selectedSortOption: e.target.value });
    // pass the filter function back to parent component with the new option,

    this.props.selectSortFilter(e.target.value);
  };

  handleTypeChange = e => {
    // get the right option from the dropdown
    // this.setState({ selectedTypeOption: e.target.value });
    // pass the filter function back to parent component with the new option,
    this.props.selectTypeFilter(e.target.value);
  };

  handleResetValue = () => {
    // reset value of filter and sort to default state.

    this.props.selectSortFilter("recent");
    this.props.selectTypeFilter("all");
  };

  render() {
    // // define sort options and type options

    // const sortOptions = [
    //   { value: 'recent', label: 'recent' },
    //   { value: 'popular', label: 'popular' },
    //   { value: 'shared', label: 'shared' },
    // ];

    // const typeOptions = [
    //   { value: 'all', label: 'stuff' },
    //   { value: 'articles', label: 'articles' },
    //   { value: 'products', label: 'products' },
    //   { value: 'videos', label: 'videos' },
    // ];
    // const { selectedSortOption } = this.state;
    // const { selectedTypeOption } = this.state;
    let resetValue;
    if (
      this.props.filterOption !== "recent" ||
      this.props.typeOption !== "all"
    ) {
      resetValue = <LinkText onClick={this.handleResetValue}>🔄</LinkText>;
    }

    return (
      <Flex direction="column" align="center">
        {/* <Box mb={5} mx="auto" px={4} py={7} color="white" bg="base" width={1}>
        <Heading is="h1" fontSize={[4, 5, 6]}>
          Get some things done
        </Heading>
      </Box> */}
        {/* <Text my={5} fontSize={3} fontWeight="bold" center color="gray9">
          What do you need to do today?
        </Text> */}

        <Flex mx="auto" my={5}>
          {/* <Input defaultValue="Hello" placeholder="Input" py={4} px={4} /> */}
          <Hide xs>
            <Box>
              <SelectText fontSize={3}>View my</SelectText>
            </Box>
          </Hide>
          <FunBox pt="5px" mr="5px" width={[1, 1 / 2]}>
            <Select
              value={this.props.filterOption}
              onChange={this.handleSortChange}
            >
              <option value="recent">recent</option>
              <option value="popular">popular</option>
              <option value="success">successful</option>
              <option value="failed">failed</option>
            </Select>
          </FunBox>

          <FunBox pt="5px">
            <Select
              value={this.props.typeOption}
              onChange={this.handleTypeChange}
            >
              <option value="all">launches </option>
              <option value="articles">articles</option>
              <option value="products">products</option>
              <option value="videos">videos</option>
              {/* <option value="experiments">experiments</option> */}
            </Select>{" "}
          </FunBox>
          <Flex flexWrap="wrap" pt={1} pl={1}>
            {resetValue}
          </Flex>

          {/* Put a dropdown arrow and an X button to reset. */}
        </Flex>
      </Flex>
    );
  }
}

export default MainSelector;

// old selector
{
  /* <FunBox mx={2} width="140px">
            {/* <Select
              defaultValue={typeOptions[0]}
              value={selectedTypeOption}
              onChange={this.handleTypeChange}
              options={typeOptions}
            /> */
}

{
  /* <SelectText fontSize={3} ml={2}>
            projects/articles/videos
          </SelectText> */
}
{
  /* <Button
            onClick={() => logOutboundLink('get started')}
            mx={[0, 2]}
            my={[2, 0]}
            px={5}
            py={4}
            f={2}
            color="gray9"
            bg="gray1"
          >
            Get Started
          </Button>

          <Button
            is="a"
            href="/feather.zip"
            onClick={() => logDownload('all')}
            download
            mx={[0, 2]}
            my={[2, 0]}
            px={5}
            py={4}
            f={2}
            color="gray9"
            bg="gray1"
          >
            Download
          </Button> */
}
