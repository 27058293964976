import React from 'react';
import Modal from 'react-modal';
import * as PropTypes from 'prop-types';
// import { StaticQuery, push } from 'gatsby';
import { Close } from 'rebass';
import { graphql } from 'gatsby';

let posts;

Modal.setAppElement(`#___gatsby`);

class GatsbyGramModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    location: PropTypes.object.isRequired,
  };

  render() {
    return (
      <StaticQuery
        query={graphql`
          query ModalPosts {
            allContentfulArticles {
              edges {
                node {
                  id
                }
              }
            }
          }
        `}
        render={data => {
          if (!posts) {
            posts = data.allPostsJson.edges.map(e => e.node);
          }
          return (
            <Modal
              isOpen={this.props.isOpen}
              onRequestClose={() => push(`/`)}
              style={{
                overlay: {
                  position: `fixed`,
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: `rgba(0, 0, 0, 0.75)`,
                },
                content: {
                  position: `absolute`,
                  border: `none`,
                  background: `none`,
                  padding: 0,
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  overflow: `auto`,
                  WebkitOverflowScrolling: `touch`,
                  maxWidth: `1024px`,
                  marginRight: `auto`,
                  marginleft: `auto`,
                },
              }}
              contentLabel="Modal"
            >
              <div
                onClick={() => push(`/`)}
                css={{
                  display: `flex`,
                  position: `relative`,
                  height: `100vh`,
                }}
              >
                <div
                  css={{
                    display: `flex`,
                    alignItems: `center`,
                    justifyItems: `center`,
                    maxWidth: rhythm(60.25), // Gets it right around Instagram's maxWidth.
                    margin: `auto`,
                    width: `100%`,
                  }}
                />
                <Close
                  data-testid="modal-close"
                  onClick={() => push(`/`)}
                  css={{
                    cursor: `pointer`,
                    color: `rgba(255,255,255,0.8)`,
                    fontSize: `30px`,
                    position: `absolute`,
                    top: rhythm(1 / 4),
                    right: rhythm(1 / 4),
                  }}
                />
              </div>
            </Modal>
          );
        }}
      />
    );
  }
}

export default GatsbyGramModal;
