import React from 'react';
import MainSelector from './MainSelector';
import MainGrid from './MainGrid';
import { runInThisContext } from 'vm';

class MainGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterSortOption: 'recent',
      filterTypeOption: 'all',
      productFilter: '',
    };
  }

  // define functions that will get sort option from child and set state of parent for dropdown menu
  selectSortFilter = sortOption => {
    this.setState({
      filterSortOption: sortOption,
    });
  };

  selectTypeFilter = typeOption => {
    this.setState({
      filterTypeOption: typeOption,
    });
  };

  // when component mounts, check to see if params are defined, and sort filter or type by that. (need to update search text too)
  componentWillMount() {

  }

  componentDidMount() {
    let goSwitch = true;
    const terms = ['popular', 'recent', 'failed', 'success', 'experiments']
    if (terms.indexOf(this.props.search) > -1 ) {
      goSwitch = null;
      if(this.props.search !== "experiments") {
        this.setState({
        filterSortOption: this.props.search,
      });

    }

      else {
        this.setState({
          filterTypeOption: 'experiments',
        })
        console.log(this.state.filterTypeOption)
      }

    }


    const localSort = localStorage.getItem('filter');
    if (localSort && goSwitch) {
      this.setState({
        filterSortOption: localSort,
      });
    }
    const localType = localStorage.getItem('type');
    if (localType && goSwitch) {
      this.setState({
        filterTypeOption: localType,
      });
    }
  }

  componentDidUpdate() {

    localStorage.setItem('filter', this.state.filterSortOption);
    localStorage.setItem('type', this.state.filterTypeOption);
  }

  render() {


    // define a variable with all three category arrays
    const joined = this.props.articles.edges.concat(this.props.products.edges);
    const threejoined = joined.concat(this.props.videos.edges);

    // build filter mechanism for defining sortOutput upon state change.
    let sortOutput = threejoined;
    if (this.state.filterTypeOption === 'all') {
      sortOutput = threejoined;
    } else if (this.state.filterTypeOption === 'articles') {
      sortOutput = this.props.articles.edges;
    } else if (this.state.filterTypeOption === 'products') {
      sortOutput = this.props.products.edges;
    } else if (this.state.filterTypeOption === 'experiments') {
      sortOutput = this.props.articles.edges.filter(
        post => post.node.experiment == true
      )
      ;
    } else if (this.state.filterTypeOption === 'videos') {
      sortOutput = this.props.videos.edges;
    }

    // now sort that filter by recent or popular depending on state.
    if (this.state.filterSortOption === 'recent') {
      sortOutput.sort((a, b) => {
        let dateA = new Date(a.node.date),
          dateB = new Date(b.node.date);
        return dateB - dateA;
      });
    } else if (this.state.filterSortOption === 'popular') {
      sortOutput.sort((a, b) => b.node.hearts - a.node.hearts);
    // build filter that will show success or failure as well. If product, tags. If not, sort unpopular.
     } else if(this.state.filterSortOption === 'failed') {
       if(this.state.filterTypeOption !== 'all' && this.state.filterTypeOption !== 'experiments') {
      const typeTag = eval(`this.props.${this.state.filterTypeOption}.edges`);
          sortOutput = typeTag.filter(
            post => post.node.tags == 'failed');
       } else if(this.state.filterTypeOption === 'all'){
        sortOutput = threejoined.filter(
          post => post.node.tags == 'failed');
       } else if(this.state.filterTypeOption === 'experiments') {
        sortOutput = this.props.articles.edges.filter(
          post => post.node.tags == 'failed' && post.node.experiment === true)
          }
        }
        // successful filter
        else if(this.state.filterSortOption === 'success') {
          if(this.state.filterTypeOption !== 'all' && this.state.filterTypeOption !== 'experiments') {
            const typeTag2 = eval(`this.props.${this.state.filterTypeOption}.edges`);
             sortOutput = typeTag2.filter(
               post => post.node.tags == 'success');
          } else if(this.state.filterTypeOption === 'all'){
           sortOutput = threejoined.filter(
             post => post.node.tags == 'success');
          } else if(this.state.filterTypeOption === 'experiments') {
           sortOutput = this.props.articles.edges.filter(
             post => post.node.tags == 'success' && post.node.experiment === true)
             }
            }



    return (
      <div>
        <MainSelector
          selectSortFilter={this.selectSortFilter}
          selectTypeFilter={this.selectTypeFilter}
          filterOption={this.state.filterSortOption}
          typeOption={this.state.filterTypeOption}
        />
        <MainGrid posts={sortOutput} />
      </div>
    );
  }
}

export default MainGroup;
