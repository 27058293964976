import React from 'react';
import Link from 'gatsby-link';
import MainGroup from '../components/MainGroup';
import Container from '../components/Container';
import HomeBanner from '../components/HomeBanner';
import MainModal from '../components/MainModal';
import Page from '../components/Page';
import { graphql } from 'gatsby';

class IndexPage extends React.Component {
  render() {
    const { data } = this.props;
    const { title } = this.props.data.allContentfulArticles;
    const searchTerm = this.props.location.search.replace('?', '');
    return (
      <div>
        <Page>
          <HomeBanner />
          <Container>
            <MainGroup
              products={this.props.data.allContentfulProducts}
              articles={this.props.data.allContentfulArticles}
              videos={this.props.data.allContentfulVideos}
              search={searchTerm}
            />
          </Container>
        </Page>
      </div>
    );
  }
}

export default IndexPage;

export const query = graphql`
  query MainQuery {
    allContentfulArticles {
      edges {
        node {
          title
          slug
          id
          date
          hearts
          experiment
          tags
          featuredImage {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulProducts {
      edges {
        node {
          title
          url
          slug
          id
          date
          tags
          hearts
          featuredImage {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulVideos {
      edges {
        node {
          title
          url
          slug
          id
          youtubeid
          date
          tags
          hearts
          featuredImage {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;
