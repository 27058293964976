import React from "react";
import styled from "styled-components";
import { Box, Container, Row, Banner, Heading, Subhead, Column } from "rebass";
import coding_real_hey from "../images/coding_real_hey.svg";
import coding_real from "../images/coding_real.svg";
import Link from "gatsby-link";
import { colors } from "../theme";
import Icon from "./Icon";
import Hide from "hidden-styled";

class HomeBanner extends React.Component {
  render() {
    const BannerHome = Banner.extend`
      min-height: 300px;
      max-height: 40vh;
      background: "base";
      text-decoration: none;
      color: ${this.props.colorFont};
    `;

    const BannerLink = styled(Link)`
      color: #c1c1cd;
      &:hover {
        color: ${colors.gray9};
        cursor: pointer;
      }
    `;

    const BadLink = styled.a`
      color: #fff;
      &:hover {
        color: ${colors.gray9};
        cursor: pointer;
      }
    `;

    const ExpLink = styled.a`
      color: #fff;
      &:hover {
        color: ${colors.gray9};
        cursor: pointer;
      }
    `;

    const EvLink = styled.a`
      color: #fff;
      &:hover {
        color: ${colors.gray9};
        cursor: pointer;
      }
    `;

    const BlockImage = styled.img`
      width: 250px;
      margin-left: auto;
      margin-right: auto;
    `;

    const HomeSubhead = styled(Subhead)`
      padding-bottom: 3px;
      color: #dee1e5;
    `;

    const BulletIcon = styled(Icon)`
      padding-right: 5px;
      padding-top: 5px;
      width: 20px;
    `;
    return (
      <BannerHome
        bg="base"
        // backgroundImage={this.props.bgImage}
        className="BannerHome"
      >
        <Container my="7vh" my={2}>
          <Row mx="auto" pt="10px">
            <Column alignSelf="center" style={{ minWidth: "50%" }}>
              <Heading is="h5" fontSize={[5, 4, 5]} color="white">
                This site is a list of all my projects
              </Heading>
              <Heading is="h5" fontSize={[2, 3, 2]} color="white">
               From successes (a few exits) to failures (many more), I've written up lessons learned from each
              </Heading>
              <br />
              <br />



            </Column>
            <Hide xs>
              {/* <Column pt="40px">
                <BlockImage
                  src={coding_real}
                  onMouseOver={(e) => (e.currentTarget.src = coding_real_hey)}
                  onMouseOut={(e) => (e.currentTarget.src = coding_real)}
                />
              </Column> */}
            </Hide>
          </Row>
        </Container>
      </BannerHome>
    );
  }
}

export default HomeBanner;


// {/* <Hide xs>
// <HomeSubhead
//   fontSize={[ 2, 3]}

//   fontWeight="initial"
// >
//   {/* <BulletIcon name="zap" /> */}
//   {`📦 Products ${'\u00a0'} – ${'\u00a0'} 1 month to earn $1000`}
// </HomeSubhead>
// <span />
// {/* prettier-ignore */}
// <HomeSubhead
//   fontSize={[ 2, 3]}
//   fontWeight="initial"
// >
//   {`📄 Articles${'\u00a0'}${'\u00a0'}${'\u00a0'}${'\u00a0'}${'\u00a0'}  –  ${'\u00a0'} 1 month to hit 100 shares`}
// </HomeSubhead>
// <span />
// <HomeSubhead fontSize={[ 2, 3]} fontWeight="initial">
//   {`🎥 Videos ${'\u00a0'}${'\u00a0'}${'\u00a0'}${'\u00a0'}${'\u00a0'} – ${'\u00a0'}${'\u00a0'}1 month to reach 1000 views`}
// </HomeSubhead>
// <br />
// <Subhead
//   fontSize={[1, 1, 1]}
//   color="#c1c1cda3"
//   fontWeight="initial"
//   lineHeight="1em"
//   ml="30px"
// >
//   <BannerLink to="algorithm">
//   Learn more about the algorithm that grades me
//   </BannerLink>
// </Subhead>
// </Hide> */}